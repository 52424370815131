












import Vue from 'vue'
import Cookies from 'js-cookie'
import { faTimes, faCookieBite } from '@fortawesome/free-solid-svg-icons'
import { BaseBtn } from '@/components'

const COOKIE_CONSTENT_HIDDEN_NAME = 'refair-cookie-consent-hidden'

export default Vue.extend({
    name: 'TheCookieConsent',

    components: { BaseBtn },

    data: () => ({
        isShown: true,
        faTimes,
        faCookieBite,
    }),

    created() {
        this.isShown = Cookies.get(COOKIE_CONSTENT_HIDDEN_NAME) == null
    },

    methods: {
        close() {
            Cookies.set(COOKIE_CONSTENT_HIDDEN_NAME, 'true', { sameSite: 'strict' })
            this.isShown = false
        },
    },
})
