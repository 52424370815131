




import Vue from 'vue'
import { BaseBtn } from '../BaseBtn'

export default Vue.extend({
    name: 'BaseCircleBtn',
    components: { BaseBtn },
})
