

















import Vue, { VueConstructor } from 'vue'
import { IUserSkill } from '@/types'

export default Vue.extend({
    name: 'SkillsByCategory',

    props: {
        categoryName: {
            type: String,
            required: true,
        },
        userSkills: {
            type: Array as () => IUserSkill[],
            required: true,
        },
        skillComponent: {
            type: Function,
            required: true,
        },
    },
})
