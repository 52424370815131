
import Vue from 'vue'
import { mergeData } from 'vue-functional-data-merge'

export default Vue.extend({
    name: 'BaseChipContainer',

    functional: true,

    render(h, { data, props, children }) {
        const componentData = {
            staticClass: 'base-chip-container',
        }
        const childNodes = children.filter((child) => !!child.tag)
        const items = childNodes.map((child) => {
            return h('div', { staticClass: 'base-chip-container__content__item' }, [child])
        })

        return h('div', mergeData(data, componentData), [
            h('div', { staticClass: 'base-chip-container__content' }, items),
        ])
    },
})
