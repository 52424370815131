


























import Vue from 'vue'
import { BaseBtnRefer } from '@/components'
import { IJobReferral } from '@/types'
import { REPOSITORY_JOB_REFFERALS } from '@/repositiories'
import { getFormattedMoneyValue } from '@/utils'

export default Vue.extend({
    name: 'InfoReferralReward',

    components: { BaseBtnRefer },

    props: {
        job: {
            type: Object,
            required: true,
        },
    },

    computed: {
        referralHash(): string {
            return this.$route.query.referral as string
        },

        signUpInvitationUuid(): string | undefined {
            return (
                this.referral?.signUpInvitationUuid ||
                (this.$route.query.invitationUuid as string)
            )
        },

        formattedMoneyValue(): string {
            return getFormattedMoneyValue(this.job.salaryMin * this.job.referralRewardFactor)
        },
    },

    data: () => ({
        referral: undefined as undefined | IJobReferral,
    }),

    async created() {
        if (!this.referralHash) return

        try {
            this.referral = await REPOSITORY_JOB_REFFERALS.fetchReferral(this.referralHash)
        } catch (error) {
            alert(error)
        }
    },
})
