






import Vue from 'vue'

export default Vue.extend({
    name: 'BaseTabs',

    props: {
        subtabs: Boolean,
    },
})
