












import Vue from 'vue'
import { BaseSection } from '../BaseSection'
import { Calculator } from './components'

export default Vue.extend({
    name: 'BaseSectionNetworkValue',

    components: { BaseSection, Calculator },
})
