




















import Vue from 'vue'
import { getFormattedMoneyValue } from '@/utils'

export default Vue.extend({
    name: 'JobSalary',

    props: {
        job: {
            type: Object,
            required: true,
        },
    },

    computed: {
        salaryMinFormatted(): string {
            return getFormattedMoneyValue(this.job.salaryMin)
        },

        salaryMaxFormatted(): string {
            return getFormattedMoneyValue(this.job.salaryMax)
        },

        hourlyRateMinFormatted(): string {
            return getFormattedMoneyValue(this.job.b2bHourlyRateMin)
        },

        hourlyRateMaxFormatted(): string {
            return getFormattedMoneyValue(this.job.b2bHourlyRateMax)
        },
    },
})
