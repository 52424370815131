













































































import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'
import {
    faList,
    faAngleDown,
    faSignOutAlt,
    faCog,
    faTachometerAlt,
    faBriefcase,
} from '@fortawesome/free-solid-svg-icons'
import { ACTION_AUTH, ROUTE, STORE_MODULE } from '@/enums'
import { IStateAuth, IStateOnboarding } from '@/types'
import { BaseAvatar } from '@/components'
import { TheTokenAutoRefresher } from './components'

const { mapState, mapActions } = createNamespacedHelpers(STORE_MODULE.AUTH)
const { mapState: mapStateOnboarding } = createNamespacedHelpers(STORE_MODULE.ONBOARDING)

export default Vue.extend({
    name: 'TheNavbarUser',

    components: { BaseAvatar, TheTokenAutoRefresher },

    data: () => ({
        ROUTE,
        faList,
        faAngleDown,
        faSignOutAlt,
        faCog,
        faTachometerAlt,
        faBriefcase,
    }),

    computed: {
        ...(mapState(['userAccount']) as MapStateToComputed<IStateAuth>),
        ...(mapStateOnboarding(['onboardingState']) as MapStateToComputed<IStateOnboarding>),
    },

    methods: {
        ...mapActions({ signOutAction: ACTION_AUTH.SIGN_OUT }),

        async signOut() {
            await this.signOutAction()

            this.$router.push({ name: ROUTE.SIGN_IN })
        },
    },
})
