






























import Vue from 'vue'
import { IconDefinition } from '@fortawesome/free-solid-svg-icons'

export default Vue.extend({
    name: 'BaseListItem',

    props: {
        icon: Object as () => IconDefinition,
    },

    computed: {
        contentComponent(): string {
            return this.$attrs.to ? 'RouterLink' : 'div'
        },

        isClickable(): boolean {
            return !!this.$listeners.click || !!this.$attrs.to
        },
    },
})
