var render, staticRenderFns
import script from "./BaseListItemProps.vue?vue&type=script&lang=ts&"
export * from "./BaseListItemProps.vue?vue&type=script&lang=ts&"
import style0 from "./BaseListItemProps.vue?vue&type=style&index=0&id=4ddaf01e&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ddaf01e",
  null
  
)

export default component.exports