











import Vue from 'vue'
import { BaseFieldText } from '../BaseFieldText'

export default Vue.extend({
    name: 'BaseFieldPasswordRepeated',

    components: { BaseFieldText },

    props: {
        password: {
            type: String,
            required: true,
        },
        isValid: Boolean,
    },

    data: () => ({
        passwordRepeated: '',
        showValidationResult: false,
    }),

    computed: {
        isValidInternal(): boolean {
            return this.password === this.passwordRepeated
        },
    },

    watch: {
        isValidInternal: {
            immediate: true,
            handler(isValid: boolean) {
                this.$emit('update:is-valid', isValid)
            },
        },
    },
})
