













import Vue from 'vue'
import {
    SkillsEssential,
    SkillsDesired,
    BasicInfo,
    Details,
    Description,
    Recruitment,
    WorkTimeDivision,
    CompanyPerks,
} from './components'

export default Vue.extend({
    name: 'BaseJobDetailsData',

    components: {
        SkillsEssential,
        SkillsDesired,
        BasicInfo,
        Details,
        Description,
        Recruitment,
        WorkTimeDivision,
        CompanyPerks,
    },

    props: {
        job: {
            type: Object,
            required: true,
        },
    },
})
