import { createApiCache, createApiCacheStaticResourceGetter } from '@/api'
import { IJobContractType } from '@/types'
import { fetchContractTypes } from './fetchContractTypes'

const cache = createApiCache<number, IJobContractType>()
const { getCachedItem } = createApiCacheStaticResourceGetter<number, IJobContractType>(
    cache,
    'id',
    fetchContractTypes
)

export async function fetchContractType(typeId: number): Promise<IJobContractType> {
    const type = await getCachedItem(typeId)

    if (!type) {
        throw new Error(`Job contract type with id: "${typeId}" does not exist`)
    }

    return type
}
