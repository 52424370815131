










import Vue from 'vue'
import { BaseFieldText } from '../BaseFieldText'

export default Vue.extend({
    name: 'BaseFieldEmail',

    components: { BaseFieldText },

    props: {
        isValid: Boolean,
    },

    data: () => ({
        showValidationResult: false,
    }),

    computed: {
        isValidInternal(): boolean {
            return /.+@.+\..+/.test(this.$attrs.value) && !/\s/.test(this.$attrs.value)
        },
    },

    watch: {
        isValidInternal: {
            immediate: true,
            handler(isValid: boolean) {
                this.$emit('update:is-valid', isValid)
            },
        },
    },
})
