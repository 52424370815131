













import Vue from 'vue'
import { IApiErrorResponseData } from '@/api/api/types'
import { ICredentials } from '@/types'
import { BaseBtn } from '@/components/BaseBtn'
import { faUnlock } from '@fortawesome/free-solid-svg-icons'
import { createNamespacedHelpers } from 'vuex'
import { ACTION_AUTH, STORE_MODULE } from '@/enums'

const { mapActions } = createNamespacedHelpers(STORE_MODULE.AUTH)

export default Vue.extend({
    name: 'BtnSignIn',

    components: { BaseBtn },

    props: {
        credentials: {
            type: Object as () => ICredentials,
            required: true,
        },
        isValid: {
            type: Boolean,
            required: true,
        },
        error: Object as () => IApiErrorResponseData,
    },

    data: () => ({
        isRequestInProgress: false,
        faUnlock,
    }),

    methods: {
        ...mapActions({ signInFromCredentials: ACTION_AUTH.SIGN_IN_FROM_CREDENTIALS }),

        async submit() {
            if (!this.isValid) return

            this.$emit('update:error', undefined)
            this.isRequestInProgress = true

            try {
                await this.signInFromCredentials(this.credentials)

                this.$emit('signed-in')
            } catch (error) {
                this.$emit('update:error', error.data)
            }

            this.isRequestInProgress = false
        },
    },
})
