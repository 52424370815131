
import Vue from 'vue'

export default Vue.extend({
    name: 'BaseCalendlyAssetLoader',

    data: () => ({
        widgetLinkElement: undefined as undefined | HTMLLinkElement,
        widgetScriptElement: undefined as undefined | HTMLScriptElement,
        isWidgetStylesLoaded: false,
        isWidgetScriptLoaded: false,
    }),

    model: {
        prop: 'areAssetsLoaded',
        event: 'update:are-assets-loaded',
    },

    props: {
        areAssetsLoaded: {
            type: Boolean,
            required: true,
        },
    },

    computed: {
        isWidgetLoaded(): boolean {
            return this.isWidgetStylesLoaded && this.isWidgetScriptLoaded
        },
    },

    watch: {
        isWidgetLoaded(isLoaded: boolean) {
            this.$emit('update:are-assets-loaded', isLoaded)
        },
    },

    created() {
        this.createWidgetLinkElement()
        this.createWidgetScriptElement()
    },

    beforeDestroy() {
        if (this.widgetLinkElement) document.body.removeChild(this.widgetLinkElement)
        if (this.widgetScriptElement) document.body.removeChild(this.widgetScriptElement)
    },

    methods: {
        createWidgetLinkElement(): void {
            this.widgetLinkElement = document.createElement('link')
            this.widgetLinkElement.href =
                'https://assets.calendly.com/assets/external/widget.css'
            this.widgetLinkElement.rel = 'stylesheet'
            this.widgetLinkElement.onload = () => (this.isWidgetStylesLoaded = true)

            document.body.appendChild(this.widgetLinkElement)
        },

        createWidgetScriptElement(): void {
            this.widgetScriptElement = document.createElement('script')
            this.widgetScriptElement.src =
                'https://assets.calendly.com/assets/external/widget.js'
            this.widgetScriptElement.type = 'text/javascript'
            this.widgetScriptElement.onload = () => (this.isWidgetScriptLoaded = true)

            document.body.appendChild(this.widgetScriptElement)
        },
    },

    render() {
        return this.$slots.default! as any
    },
})
