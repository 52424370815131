
import { BModal } from 'bootstrap-vue'

export default BModal.extend({
    name: 'BaseModal',

    props: {
        headerBgVariant: {
            default: 'dark',
        },
        headerTextVariant: {
            default: 'white',
        },
        headerClass: {
            default: 'rounded-0',
        },
        contentClass: {
            default: 'base-dialog-content',
        },
    },
})
