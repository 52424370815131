










import Vue from 'vue'
import { BaseCard, BaseCardTitle, BaseCardBody } from '@/components'
// @ts-ignore
import VueMarkdown from 'vue-markdown'
import { faEdit } from '@fortawesome/free-solid-svg-icons'

export default Vue.extend({
    name: 'Description',

    components: { BaseCard, BaseCardTitle, BaseCardBody, VueMarkdown },

    props: {
        job: {
            type: Object,
            required: true,
        },
    },

    data: () => ({ faEdit }),
})
