
























import Vue from 'vue'
import debounce from 'lodash/debounce'
import { BaseFieldTypeahead } from '../BaseFieldTypeahead'
import { BaseFieldTypeaheadSuggestion } from '../BaseFieldTypeaheadSuggestion'
import { ICompany } from '@/types'
import { DEBOUNCE_TIME_MS } from '@/enums'
import { REPOSITORY_COMPANIES } from '@/repositiories'
import { IconDefinition } from '@fortawesome/free-solid-svg-icons'

interface ICompanyItem {
    company: ICompany
    disabled: boolean
}

export default Vue.extend({
    name: 'BaseFieldTypeaheadCompany',

    components: { BaseFieldTypeahead, BaseFieldTypeaheadSuggestion },

    props: {
        disabledCompanies: {
            type: Array as () => ICompany[],
            default: () => [],
        },
        label: {
            type: String,
            default: 'Company',
        },
        icon: Object as () => IconDefinition,
        singleLine: Boolean,
        loading: Boolean,
        canCreateCompany: Boolean,
        showAlreadySelected: Boolean,
    },

    data: () => ({
        searchText: '',
        companies: [] as ICompany[],
        isLoadingCompanies: false,
        isCreatingCompany: false,
    }),

    computed: {
        isLoading(): boolean {
            return this.isLoadingCompanies || this.isCreatingCompany || this.loading
        },

        companyItems(): ICompanyItem[] {
            return this.companies.map((company) => ({
                company,
                disabled: !!this.disabledCompanies.find(({ id }) => id === company.id),
            }))
        },

        disabledCompanyItemValues(): string[] {
            return this.companyItems.filter(({ disabled }) => disabled).map(this.serializer)
        },
    },

    methods: {
        serializer({ company }: ICompanyItem) {
            return company.name
        },

        inputHandler(searchText: string) {
            this.isLoadingCompanies = true
            this.fetchCompaniesDebounced(searchText)
        },

        fetchCompaniesDebounced: debounce(function (this: any, searchText: string) {
            this.fetchCompanies(searchText)
        }, DEBOUNCE_TIME_MS),

        async fetchCompanies(searchText: string) {
            try {
                const { items } = await REPOSITORY_COMPANIES.fetchCompanies({ searchText })

                this.companies = items
            } catch (error) {
                alert(error.data.message)
            }

            this.isLoadingCompanies = false
        },

        async createCompany(name: string) {
            this.isCreatingCompany = true

            try {
                const company = await REPOSITORY_COMPANIES.createCompany({ name })

                this.emitCompany(company)
            } catch (error) {
                alert(error.data.message)
            }

            this.isCreatingCompany = false
        },

        async emitCompany(company: ICompany) {
            this.searchText = ''
            this.$emit('hit', company)
        },

        focus(): void {
            const typeahead = this.$refs.typeahead as any

            typeahead.focus()
        },
    },
})
