














import Vue from 'vue'
import { BaseTabs, BaseTab } from '@/components/BaseTabs'
import { ITabAuthentication } from '../../types'

export default Vue.extend({
    name: 'Tabs',

    components: { BaseTabs, BaseTab },

    model: {
        prop: 'selectedTab',
        event: 'update:selected-tab',
    },

    props: {
        tabs: {
            type: Array as () => ITabAuthentication[],
            required: true,
        },
        selectedTab: Object as () => ITabAuthentication,
    },

    methods: {
        selectTab(tab: ITabAuthentication) {
            this.$emit('update:selected-tab', tab)
        },
    },
})
