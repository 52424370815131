




import Vue from 'vue'
import debounce from 'lodash/debounce'
import { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { BaseFieldText } from '../BaseFieldText'
import { DEBOUNCE_TIME_MS } from '@/enums'

const VALUE_REGEXP = /^(\d{0,}|\d{0,}\.\d{0,})$/

export default Vue.extend({
    name: 'BaseFieldNumber',

    components: { BaseFieldText },

    model: {
        prop: 'value',
        event: 'update:value',
    },

    props: {
        value: {
            type: Number,
            required: true,
        },
        label: {
            type: String,
            default: 'Value',
        },
        precision: {
            type: Number,
            default: 2,
        },
        icon: Object as () => IconDefinition,
        max: Number,
        singleLine: Boolean,
        loading: Boolean,
        lazy: Boolean,
        debounce: Boolean,
        zeroPlaceholder: Boolean,
    },

    data: () => ({
        componentKey: Math.random(),
        autofocus: false,
    }),

    computed: {
        attrs(): Record<string, unknown> {
            return {
                type: 'tel',
                ...this.$attrs,
                label: this.label,
                icon: this.icon,
                loading: this.loading,
                singleLine: this.singleLine,
                value: this.formattedValue,
                autofocus: this.autofocus || this.$attrs.autofocus,
            }
        },

        listeners(): Record<string, unknown> {
            return {
                ...this.$listeners,
                input: this.handleInput,
                change: this.handleChange,
            }
        },

        formattedValue(): string {
            return this.zeroPlaceholder && !this.value ? '' : this.value.toString()
        },
    },

    methods: {
        handleInput(rawValue: string) {
            if (this.debounce) {
                this.handleInputDebounced(rawValue, !this.lazy)
            } else {
                this.updateValue('input', rawValue, !this.lazy)
            }
        },

        handleInputDebounced: debounce(function (
            this: any,
            rawValue: string,
            emitModelValue: boolean
        ) {
            this.updateValue('input', rawValue, emitModelValue)
        },
        DEBOUNCE_TIME_MS),

        handleChange(rawValue: string) {
            this.updateValue('change', rawValue, this.lazy)
        },

        updateValue(eventName: string, rawValue: string, emitModelValue: boolean) {
            rawValue = rawValue.replace(',', '.')

            if (!this.isValueValid(rawValue)) return this.rerender()

            const value = this.getParsedValue(rawValue)

            this.$emit(eventName, value)
            if (emitModelValue) this.$emit('update:value', value)
        },

        isValueValid(rawValue: string): boolean {
            const [, fractional = ''] = rawValue.split('.')

            return (
                VALUE_REGEXP.test(rawValue) &&
                fractional.length <= this.precision &&
                parseFloat(rawValue || '0') < Number.MAX_SAFE_INTEGER
            )
        },

        getParsedValue(rawValue: string): number {
            const value = parseFloat(rawValue)
            const safeValue = !isNaN(value) && isFinite(value) ? value : 0

            if (this.max && safeValue > this.max) return this.max

            return safeValue
        },

        async rerender() {
            this.autofocus = true

            await this.$nextTick()

            this.componentKey = Math.random()
        },
    },
})
