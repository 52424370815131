import { render, staticRenderFns } from "./FormResetPassword.vue?vue&type=template&id=292f4b65&scoped=true&"
import script from "./FormResetPassword.vue?vue&type=script&lang=ts&"
export * from "./FormResetPassword.vue?vue&type=script&lang=ts&"
import style0 from "./FormResetPassword.vue?vue&type=style&index=0&id=292f4b65&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "292f4b65",
  null
  
)

export default component.exports