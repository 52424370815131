




















import Vue from 'vue'
import { BaseTabs, BaseTab } from '@/components/BaseTabs'

export default Vue.extend({
    name: 'Tabs',

    components: { BaseTabs, BaseTab },

    model: {
        prop: 'isCandidateFormShown',
        event: 'update:is-candidate-form-shown',
    },

    props: {
        isCandidateFormShown: {
            type: Boolean,
            required: true,
        },
    },

    computed: {
        isCandidateFormShownInternal: {
            get(): boolean {
                return this.isCandidateFormShown
            },
            set(value: boolean) {
                this.$emit('update:is-candidate-form-shown', value)
            },
        },
    },
})
