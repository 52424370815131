











import Vue from 'vue'

export default Vue.extend({
    name: 'BaseListItemPropChip',

    props: {
        label: {
            type: String,
            required: true,
        },
    },

    computed: {
        isClickable(): boolean {
            return !!this.$listeners.click
        },
    },
})
