













































































import Vue from 'vue'
import { REPOSITORY_NETWORK_WORTH_CALCULATION } from '@/repositiories'
import { faHandHoldingUsd } from '@fortawesome/free-solid-svg-icons'
import { INetworkWorthCalculation, IStateSignUp } from '@/types'
import { BaseSpinner } from '@/components/BaseSpinner'
import { BaseBtn } from '@/components/BaseBtn'
import { BaseFieldNumber } from '@/components/BaseField'
import { createNamespacedHelpers } from 'vuex'
import { STORE_MODULE } from '@/enums'

const { mapState } = createNamespacedHelpers(STORE_MODULE.SIGN_UP)
const NETWORK_SIZE_DEFAULT = 100

export default Vue.extend({
    name: 'NetworkWorthCalculation',

    components: { BaseFieldNumber, BaseBtn },

    data: () => ({
        faHandHoldingUsd,
        networkSize: NETWORK_SIZE_DEFAULT,
        calculation: undefined as undefined | INetworkWorthCalculation,
        isRequestInProgress: false,
    }),

    computed: {
        ...(mapState(['invitationUuid']) as MapStateToComputed<IStateSignUp>),
    },

    methods: {
        async calculate() {
            this.isRequestInProgress = true

            try {
                this.calculation = await REPOSITORY_NETWORK_WORTH_CALCULATION.fetchCalculation(
                    this.networkSize,
                    this.invitationUuid
                )
            } catch (error) {
                alert(error.data.message)
            }

            this.isRequestInProgress = false
        },
    },

    created() {
        this.calculate()
    },
})
