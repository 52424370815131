



































































import Vue from 'vue'
import { BaseCard, BaseCardTitle, BaseCardBody } from '@/components'
import { faProjectDiagram } from '@fortawesome/free-solid-svg-icons'

export default Vue.extend({
    name: 'BasicInfo',

    components: { BaseCard, BaseCardTitle, BaseCardBody },

    props: {
        job: {
            type: Object,
            required: true,
        },
    },

    data: () => ({ faProjectDiagram }),

    computed: {
        formattedContractType(): string {
            return this.job.contractType.join(',')
        },
    },
})
