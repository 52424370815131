










import Vue from 'vue'
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'
import { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { BaseField } from '../../BaseField'

export default Vue.extend({
    name: 'BaseFieldTypeahead',

    components: { BaseField, VueTypeaheadBootstrap },

    model: {
        prop: 'value',
        event: 'input',
    },

    props: {
        label: {
            type: String,
            required: true,
        },
        icon: Object as () => IconDefinition,
        singleLine: Boolean,
        loading: Boolean,
    },

    computed: {
        attrs(): object {
            return {
                minMatchingChars: 1,
                maxMatches: 5,
                showAllResults: true,
                ...(this.singleLine && { placeholder: this.label }),
                ...this.$attrs,
            }
        },
    },

    methods: {
        focus(): void {
            this.$el.getElementsByTagName('input')[0]?.focus()
        },
    },
})
