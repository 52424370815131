







import Vue from 'vue'
import { InfoSalary, InfoReferralReward } from './components'

export default Vue.extend({
    name: 'Info',

    components: { InfoSalary, InfoReferralReward },

    props: {
        job: {
            type: Object,
            required: true,
        },
    },
})
