





































import Vue from 'vue'
import { IInvitationSignUp, IUserSignUpCandidateConfig, IPerson } from '@/types'
import { BaseSpinner } from '@/components/BaseSpinner'
import {
    BaseFieldPerson,
    BaseFieldEmail,
    BaseFieldPassword,
    BaseFieldPasswordRepeated,
} from '@/components/BaseField'
import { BtnSignUp } from './components'
import { LegalDocuments } from '../LegalDocuments'
import { createNamespacedHelpers } from 'vuex'
import { ACTION_AUTH, STORE_MODULE } from '@/enums'

const { mapActions } = createNamespacedHelpers(STORE_MODULE.AUTH)

/**
 * @emits 'signed-up'
 * @emits 'signed-in'
 */
export default Vue.extend({
    name: 'FormSignUpCandidate',

    components: {
        BaseSpinner,
        BaseFieldPerson,
        BaseFieldEmail,
        BaseFieldPassword,
        BaseFieldPasswordRepeated,
        BtnSignUp,
        LegalDocuments,
    },

    props: {
        invitation: Object as () => IInvitationSignUp,
    },

    data() {
        const { firstName = '', lastName = '', email = '', uuid: invitationUuid } =
            this.invitation || {}

        return {
            accountConfig: {
                firstName,
                lastName,
                email,
                password: '',
                invitationUuid,
                checkedConsentUuids: [],
            } as IUserSignUpCandidateConfig,
            isValidPerson: false,
            isValidEmail: false,
            isValidPassword: false,
            isValidPasswordRepeated: false,
            isValidLegalDocuments: false,
            isSignInInProgress: false,
        }
    },

    computed: {
        person: {
            get(): IPerson {
                const { firstName, lastName } = this.accountConfig

                return { firstName, lastName }
            },
            set(person: IPerson) {
                this.accountConfig = { ...this.accountConfig, ...person }
            },
        },

        isValid(): boolean {
            return (
                this.isValidPerson &&
                this.isValidEmail &&
                this.isValidPassword &&
                this.isValidPasswordRepeated &&
                this.isValidLegalDocuments
            )
        },
    },

    methods: {
        ...mapActions({ signInFromCredentials: ACTION_AUTH.SIGN_IN_FROM_CREDENTIALS }),

        async signedUpHandler() {
            this.isSignInInProgress = true

            await this.signIn()

            this.$emit('signed-up')
            this.isSignInInProgress = false
        },

        async signIn(): Promise<void> {
            try {
                await this.signInFromCredentials({
                    email: this.accountConfig.email,
                    password: this.accountConfig.password,
                })

                this.$emit('signed-in')
            } catch (error) {
                alert(error.message)
            }
        },
    },
})
