
import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'
import { ACTION_AUTH, STORE_MODULE } from '@/enums'
import { IUserAccount } from '@/types'

const { mapActions } = createNamespacedHelpers(STORE_MODULE.AUTH)

const EXPIRATION_CHECK_INTERVAL_MS = 30 /* s */ * 1e3
const EXPIRATION_TIME_REFRESH_OFFSET_MS = 60 /* s */ * 1e3

export default Vue.extend({
    name: 'TheTokenAutoRefresher',

    props: {
        userAccount: {
            type: Object as () => IUserAccount,
            required: true,
        },
    },

    data: () => ({
        isActive: true,
    }),

    created() {
        this.scheduleNextCheck()
    },

    async beforeDestroy() {
        this.isActive = false
    },

    methods: {
        ...mapActions({
            refreshToken: ACTION_AUTH.REFRESH,
        }),

        scheduleNextCheck() {
            setTimeout(this.checkExpiration, EXPIRATION_CHECK_INTERVAL_MS)
        },

        async checkExpiration() {
            if (!this.isActive) return

            if (this.shouldTokenBeRefreshed()) {
                try {
                    await this.refreshToken()
                } catch (e) {}
            }

            this.scheduleNextCheck()
        },

        shouldTokenBeRefreshed(): boolean {
            return (
                this.userAccount.tokenExpirationTimestampMs <=
                Date.now() + EXPIRATION_TIME_REFRESH_OFFSET_MS
            )
        },
    },

    render: () => null as any,
})
