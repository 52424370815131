








import Vue from 'vue'
import { BaseFormAuthenticate } from '@/components/BaseForm'

export default Vue.extend({
    name: 'FormAuthenticate',

    components: { BaseFormAuthenticate },

    props: {
        signUpInvitationUuid: String,
    },
})
