





















import Vue from 'vue'
import { BaseSpinner } from '@/components/BaseSpinner'
import { IconDefinition } from '@fortawesome/free-solid-svg-icons'

export default Vue.extend({
    name: 'BaseField',

    components: { BaseSpinner },

    props: {
        label: {
            type: String,
            required: true,
        },
        icon: Object as () => IconDefinition,
        singleLine: Boolean,
        loading: Boolean,
    },
})
