







import { getFormattedKnowledgeLevel } from '@/utils'
import Vue from 'vue'
import { BaseFieldSlider } from '../BaseFieldSlider'

export default Vue.extend({
    name: 'BaseFieldSliderKnowledge',

    components: { BaseFieldSlider },

    model: {
        prop: 'value',
        event: 'update:value',
    },

    props: {
        value: Number,
    },

    computed: {
        attrs(): object {
            return {
                label: 'Knowledge level',
                lazy: true,
                ...this.$attrs,
                tooltipFormatter: this.tooltipFormatter,
                max: 100,
                interval: 12.5,
                hideDotValue: true,
            }
        },

        valueInternal: {
            get(): number {
                return this.value || 0
            },
            set(value: number) {
                this.$emit('update:value', Math.floor(value))
            },
        },
    },

    methods: {
        tooltipFormatter(value: number) {
            return getFormattedKnowledgeLevel(value)
        },
    },
})
