

























import { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import Vue from 'vue'
import { BaseField } from '../BaseField'
import { BaseFieldSlider } from '../BaseFieldSlider'

export default Vue.extend({
    name: 'BaseFieldRemoteTime',

    components: { BaseField, BaseFieldSlider },

    model: {
        prop: 'remoteTime',
        event: 'update:remote-time',
    },

    props: {
        remoteTime: Number,
        label: {
            type: String,
            default: 'Remote time',
        },
        icon: Object as () => IconDefinition,
        loading: Boolean,
    },

    computed: {
        // Server returns 0-100 number or null, but for slider we use days of week (0-5)
        remoteTimeSliderValue: {
            get(): number {
                return this.getDaysFromRemoteTime(this.remoteTime)
            },
            set(value: number) {
                this.updateRemoteTime(value * 20)
            },
        },

        isRemoteTimeEnabled(): boolean {
            return this.remoteTime !== null
        },

        textValue(): string {
            switch (true) {
                case this.remoteTime === null:
                    return 'No remote time preference'
                case this.remoteTime === 100:
                    return 'Fully remote'
                case this.remoteTime > 20:
                    return `${this.getDaysFromRemoteTime(this.remoteTime)} days a week remotely`
                case this.remoteTime === 20:
                    return `${this.getDaysFromRemoteTime(this.remoteTime)} day a week remotely`
                case this.remoteTime === 0:
                    return `Don't want to work remotely`
                default:
                    throw new Error(`Unknown remote time preference ${this.remoteTime}`)
            }
        },
    },

    methods: {
        getDaysFromRemoteTime(value: number | null): number {
            return value !== null ? value / 20 : 0
        },

        toggleRemoteTimeEnabled() {
            this.updateRemoteTime(this.remoteTime === null ? 0 : null)
        },

        updateRemoteTime(value: number | null) {
            this.$emit('update:remote-time', value)
        },
    },
})
