










import Vue from 'vue'
import { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { BaseField } from '../BaseField'

export default Vue.extend({
    name: 'BaseFieldSelect',

    components: { BaseField },

    model: {
        prop: 'value',
        event: 'input',
    },

    props: {
        label: {
            type: String,
            required: true,
        },
        icon: Object as () => IconDefinition,
        singleLine: Boolean,
        loading: Boolean,
    },

    computed: {
        attrs(): object {
            return {
                ...(this.singleLine && { placeholder: this.label }),
                ...this.$attrs,
            }
        },
    },
})
