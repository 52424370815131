import { render, staticRenderFns } from "./BaseFieldSlider.vue?vue&type=template&id=79811af7&scoped=true&"
import script from "./BaseFieldSlider.vue?vue&type=script&lang=ts&"
export * from "./BaseFieldSlider.vue?vue&type=script&lang=ts&"
import style0 from "./BaseFieldSlider.vue?vue&type=style&index=0&id=79811af7&lang=sass&scoped=true&"
import style1 from "vue-slider-component/theme/antd.css?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79811af7",
  null
  
)

export default component.exports