















import { IAddress } from '@/types'
import Vue from 'vue'

export default Vue.extend({
    name: 'BaseFieldGoogleAdressAutocomplete',

    data: () => ({
        autocomplete: undefined as undefined | google.maps.places.Autocomplete,
    }),

    mounted() {
        this.autocomplete = new google.maps.places.Autocomplete(
            this.$refs.autocomplete as HTMLInputElement,
            {
                types: ['geocode'],
            }
        )

        this.autocomplete.addListener('place_changed', () => {
            let place:
                | google.maps.places.PlaceResult
                | undefined = this.autocomplete?.getPlace()
            if (place) {
                let address = this.mapLocationToAdress(place)
                if (address) this.addAddress(address)
            }
        })
    },

    beforeDestroy() {
        this.autocomplete?.unbindAll()
    },

    methods: {
        addAddress(address: IAddress) {
            const isAddressValid = address.streetName.length

            if (isAddressValid) {
                this.$emit('addAddress', address)
            } else {
                alert('Please specify exact address (city, route, street number)')
            }

            ;(this.$refs.autocomplete as HTMLInputElement).value = ''
        },

        getAddressValueByType(
            components: google.maps.GeocoderAddressComponent[],
            type: string
        ) {
            let component = components?.find((c) => c.types[0] === type)
            return component ? component.long_name : ''
        },

        mapLocationToAdress(location: google.maps.places.PlaceResult): IAddress | undefined {
            if (!location.address_components) return

            return {
                houseNumber: this.getAddressValueByType(
                    location.address_components,
                    'street_number'
                ),
                postalCode: this.getAddressValueByType(
                    location.address_components,
                    'postal_code'
                ),
                streetName: this.getAddressValueByType(location.address_components, 'route'),
                cityName: this.getAddressValueByType(location.address_components, 'locality'),
                countryName: this.getAddressValueByType(location.address_components, 'country'),
                apartmentNumber: '',
            }
        },
    },
})
