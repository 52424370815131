



















import { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import Vue from 'vue'

export default Vue.extend({
    name: 'SkillOptionMenu',

    props: {
        icon: {
            type: Object as () => IconDefinition,
            required: true,
        },
        highlighted: Boolean,
    },
})
