

































import Vue from 'vue'
import Slider from 'vue-slider-component'
import { BaseSpinner } from '@/components/BaseSpinner'
import { IconDefinition } from '@fortawesome/free-solid-svg-icons'

export default Vue.extend({
    name: 'BaseFieldSlider',

    components: { Slider, BaseSpinner },

    props: {
        label: {
            type: String,
            required: true,
        },
        icon: Object as () => IconDefinition,
        loading: Boolean,
        singleLine: Boolean,
        hideDotValue: Boolean,
        min: {
            type: Number,
            default: 0,
        },
        max: {
            type: Number,
            default: 100,
        },
    },

    computed: {
        attrs(): object {
            return {
                dotSize: 28,
                ...this.$attrs,
                min: this.min,
                max: this.max,
            }
        },
    },

    methods: {
        dragEndHandler(value: number) {
            this.$emit('input', value)
        },
    },
})
