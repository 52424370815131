


























import Vue from 'vue'
import debounce from 'lodash/debounce'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { BaseFieldTypeahead } from '../BaseFieldTypeahead'
import { BaseFieldTypeaheadSuggestion } from '../BaseFieldTypeaheadSuggestion'
import { ISkill, IUserSkill, IUserSkillConfig } from '@/types'
import { DEBOUNCE_TIME_MS } from '@/enums'
import { REPOSITORY_SKILLS } from '@/repositiories'

interface ISkillItem extends ISkill {
    disabled: boolean
}

export default Vue.extend({
    name: 'BaseFieldTypeaheadAddUserSkill',

    components: { BaseFieldTypeahead, BaseFieldTypeaheadSuggestion },

    props: {
        userSkills: {
            type: Array as () => IUserSkill[],
            required: true,
        },
        singleLine: Boolean,
        loading: Boolean,
    },

    data: () => ({
        searchText: '',
        skills: [] as ISkill[],
        isLoadingSkills: false,
        faPlus,
    }),

    computed: {
        isLoading(): boolean {
            return this.isLoadingSkills || this.loading
        },

        skillItems(): ISkillItem[] {
            return this.skills.map((skill) => ({
                ...skill,
                disabled: !!this.userSkills.find(({ skillUuid, categoryUuid }) => {
                    return skillUuid === skill.uuid && categoryUuid === skill.category?.uuid
                }),
            }))
        },

        disabledSkillItemValues(): string[] {
            return this.skillItems.filter(({ disabled }) => disabled).map(this.serializer)
        },

        isCustomSkillAlreadyAdded(): boolean {
            const nameLower = this.searchText.toLowerCase()
            const customSkills = this.userSkills.filter(({ skillUuid }) => !skillUuid)

            return !!customSkills.find(({ skillName }) => skillName.toLowerCase() === nameLower)
        },
    },

    methods: {
        serializer(skill: ISkill) {
            return `${skill.category.name} ${skill.name}`
        },

        inputHandler(searchText: string) {
            this.isLoadingSkills = true
            this.fetchSkillsDebounced(searchText)
        },

        fetchSkillsDebounced: debounce(function (this: any, searchText: string) {
            this.fetchSkills(searchText)
        }, DEBOUNCE_TIME_MS),

        async fetchSkills(searchText: string) {
            this.isLoadingSkills = true

            try {
                const { items } = await REPOSITORY_SKILLS.fetchSkills({ searchText })

                this.skills = items
            } catch (error) {
                alert(error.data.message)
            }

            this.isLoadingSkills = false
        },

        async emitUserSkillConfig(skill: ISkill) {
            this.searchText = ''
            this.$emit('hit', this.createUserSkillConfig(skill))
        },

        createUserSkillConfig(skill: Partial<ISkill> & Pick<ISkill, 'name'>): IUserSkillConfig {
            const { name: skillName, uuid: skillUuid, category } = skill

            return {
                skillName,
                ...(skillUuid && { skillUuid }),
                ...(category && { categoryUuid: category.uuid }),
                ...(category && { categoryName: category.name }),
            }
        },

        focus(): void {
            const typeahead = this.$refs.typeahead as any

            typeahead.focus()
        },
    },
})
