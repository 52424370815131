import { render, staticRenderFns } from "./JobReferral.vue?vue&type=template&id=5cc18617&scoped=true&"
import script from "./JobReferral.vue?vue&type=script&lang=ts&"
export * from "./JobReferral.vue?vue&type=script&lang=ts&"
import style0 from "./JobReferral.vue?vue&type=style&index=0&id=5cc18617&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cc18617",
  null
  
)

export default component.exports