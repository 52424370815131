


















































import Vue from 'vue'
import { IApiErrorResponseData } from '@/api/api/types'
import { ICredentials } from '@/types'
import { BaseFieldEmail, BaseFieldPassword } from '@/components/BaseField'
import { BtnSignIn, BtnResetPassword, FormResetPassword } from './components'

/**
 * @emits  'signed-in'
 */
export default Vue.extend({
    name: 'FormSignIn',

    components: {
        BaseFieldEmail,
        BaseFieldPassword,
        BtnSignIn,
        BtnResetPassword,
        FormResetPassword,
    },

    data: () => ({
        credentials: {
            email: '',
            password: '',
        } as ICredentials,
        error: undefined as undefined | IApiErrorResponseData,
        isValidEmail: false,
        isValidPassword: false,
        isResetPasswordFormShown: false,
        isResetPasswordSuccessAlertShown: false,
    }),

    computed: {
        isValid(): boolean {
            return this.isValidEmail && this.isValidPassword
        },
    },

    methods: {
        signedHandler() {
            this.$emit('signed-in')
        },
    },
})
