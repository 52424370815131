
















import { STORE_MODULE } from '@/enums'
import { IStateAuth } from '@/types'
import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'
import { BaseModal } from '@/components/BaseModal'
import { FormAuthenticate, FormReferral } from './components'

const { mapState } = createNamespacedHelpers(STORE_MODULE.AUTH)

export default Vue.extend({
    name: 'DialogRefer',

    components: { BaseModal, FormAuthenticate, FormReferral },

    model: {
        prop: 'isShown',
        event: 'update:is-shown',
    },

    props: {
        isShown: {
            type: Boolean,
            required: true,
        },
        job: {
            type: Object,
            required: true,
        },
        signUpInvitationUuid: String,
        source: String,
    },

    computed: {
        ...(mapState(['userAccount']) as MapStateToComputed<IStateAuth>),

        isShownInternal: {
            get(): boolean {
                return this.isShown
            },
            set(isShown: boolean) {
                this.$emit('update:is-shown', isShown)
            },
        },
    },
})
