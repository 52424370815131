












import Vue from 'vue'
import { BaseBtn } from '@/components/BaseBtn'
import { faLifeRing } from '@fortawesome/free-solid-svg-icons'

export default Vue.extend({
    name: 'BtnResetPassword',

    components: { BaseBtn },

    data: () => ({ faLifeRing }),
})
