



















import Vue from 'vue'
import { getFormattedMoneyValue } from '@/utils'
import { BaseBtnRefer } from '@/components/BaseBtn'

export default Vue.extend({
    name: 'JobReferral',

    components: { BaseBtnRefer },

    props: {
        job: {
            type: Object,
            required: true,
        },
    },

    computed: {
        rewardFormatted(): string {
            return getFormattedMoneyValue(this.job.salaryMin * this.job.referralRewardFactor)
        },
    },
})
