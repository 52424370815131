


























import { IPerson } from '@/types'
import Vue from 'vue'
import { BaseFieldText } from '../BaseFieldText'

export default Vue.extend({
    name: 'BaseFieldPerson',

    components: { BaseFieldText },

    model: {
        prop: 'person',
        event: 'update:person',
    },

    props: {
        person: {
            type: Object as () => IPerson,
            required: true,
        },
        isValid: Boolean,
    },

    data: () => ({
        showValidationResultFirstName: false,
        showValidationResultLastName: false,
    }),

    computed: {
        firstName: {
            get(): string {
                return this.person.firstName
            },
            set(firstName: string) {
                this.$emit('update:person', { ...this.person, firstName })
            },
        },

        lastName: {
            get(): string {
                return this.person.lastName
            },
            set(lastName: string) {
                this.$emit('update:person', { ...this.person, lastName })
            },
        },

        isValidInternal(): boolean {
            return !!this.person.firstName.length && !!this.person.lastName.length
        },
    },

    watch: {
        isValidInternal: {
            immediate: true,
            handler(isValid: boolean) {
                this.$emit('update:is-valid', isValid)
            },
        },
    },

    methods: {
        firstNameChangeHandler() {
            this.showValidationResultFirstName = true
            this.$emit('change', this.person)
        },

        lastNameChangeHandler() {
            this.showValidationResultLastName = true
            this.$emit('change', this.person)
        },
    },
})
