












import Vue from 'vue'
import { CompanyLogo, Title, Info } from './components'

export default Vue.extend({
    name: 'BaseJobDetailsHeader',

    components: { CompanyLogo, Title, Info },

    props: {
        job: {
            type: Object,
            required: true,
        },
    },
})
