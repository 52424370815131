





















import Vue from 'vue'
import { IMoney } from '@/types'
import { BaseField } from '../BaseField'
import { BaseFieldNumber } from '../BaseFieldNumber'
import { BaseFieldCurrency } from '../BaseFieldCurrency'
import { IconDefinition } from '@fortawesome/free-solid-svg-icons'

export default Vue.extend({
    name: 'BaseFieldMoney',

    components: { BaseField, BaseFieldNumber, BaseFieldCurrency },

    model: {
        prop: 'money',
        event: 'update:money',
    },

    props: {
        money: {
            type: Object as () => IMoney,
            required: true,
        },
        label: {
            type: String,
            default: 'Value',
        },
        icon: Object as () => IconDefinition,
        singleLine: Boolean,
        hideZero: Boolean,
        loading: Boolean,
        lazy: Boolean,
        decimal: Boolean,
    },

    computed: {
        value: {
            get(): number {
                return this.money.value
            },
            set(value: number) {
                this.update({ value })
            },
        },

        currency: {
            get(): string {
                return this.money.currency
            },
            set(currency: string) {
                this.update({ currency })
            },
        },
    },

    methods: {
        update(payload: Partial<IMoney>) {
            this.$emit('update:money', { ...this.money, ...payload })
        },
    },
})
