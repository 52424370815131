



















import Vue from 'vue'
import debounce from 'lodash/debounce'
import { BaseFieldTypeahead } from '../BaseFieldTypeahead'
import { BaseFieldTypeaheadSuggestion } from '../BaseFieldTypeaheadSuggestion'
import { ICity } from '@/types'
import { DEBOUNCE_TIME_MS } from '@/enums'
import { REPOSITORY_CITIES } from '@/repositiories'
import { IconDefinition } from '@fortawesome/free-solid-svg-icons'

interface ICityItem {
    city: ICity
    disabled: boolean
}

export default Vue.extend({
    name: 'BaseFieldTypeaheadCity',

    components: { BaseFieldTypeahead, BaseFieldTypeaheadSuggestion },

    props: {
        disabledCities: {
            type: Array as () => ICity[],
            default: () => [],
        },
        label: {
            type: String,
            default: 'City',
        },
        icon: Object as () => IconDefinition,
        singleLine: Boolean,
        loading: Boolean,
        showAlreadySelected: Boolean,
    },

    data: () => ({
        searchText: '',
        cities: [] as ICity[],
        isLoadingCities: false,
    }),

    computed: {
        isLoading(): boolean {
            return this.isLoadingCities || this.loading
        },

        cityItems(): ICityItem[] {
            return this.cities.map((city) => ({
                city,
                disabled: !!this.disabledCities.find(({ id }) => id === city.id),
            }))
        },

        disabledCityItemValues(): string[] {
            return this.cityItems.filter(({ disabled }) => disabled).map(this.serializer)
        },
    },

    methods: {
        serializer({ city }: ICityItem) {
            return `${city.name} ${city.country}`
        },

        inputHandler(searchText: string) {
            this.isLoadingCities = true
            this.fetchCitiesDebounced(searchText)
        },

        fetchCitiesDebounced: debounce(function (this: any, searchText: string) {
            this.fetchCities(searchText)
        }, DEBOUNCE_TIME_MS),

        async fetchCities(searchText: string) {
            this.isLoadingCities = true

            try {
                const { items } = await REPOSITORY_CITIES.fetchCities({ searchText })

                this.cities = items
            } catch (error) {
                alert(error.data.message)
            }

            this.isLoadingCities = false
        },

        async emitCity({ city }: ICityItem) {
            this.searchText = ''
            this.$emit('hit', city)
        },

        focus(): void {
            const typeahead = this.$refs.typeahead as any

            typeahead.focus()
        },
    },
})
