













import { REPOSITORY_LEGAL_DOCUMENTS } from '@/repositiories'
import { IUuid, ILegalDocument } from '@/types'
import { LegalDocumentsItem } from './components'
import { BaseSpinner } from '@/components/BaseSpinner'
import Vue from 'vue'

export default Vue.extend({
    name: 'LegalDocuments',

    components: { LegalDocumentsItem, BaseSpinner },

    props: {
        checkedConsentUuids: {
            type: Array as () => IUuid[],
            required: true,
        },
        isValid: {
            type: Boolean,
            required: true,
        },
    },

    data: () => ({
        legalDocuments: undefined as undefined | ILegalDocument[],
    }),

    computed: {
        checkedConsentUuidsInternal: {
            get(): IUuid[] {
                return this.checkedConsentUuids
            },
            set(checkedConsentUuids: IUuid[]) {
                this.$emit('update:checked-consent-uuids', checkedConsentUuids)
            },
        },

        isValidInternal(): boolean {
            return !!this.legalDocuments?.every((document) =>
                this.checkedConsentUuids.includes(document.uuid)
            )
        },
    },

    watch: {
        isValidInternal: {
            immediate: true,
            handler(isValid: boolean) {
                this.$emit('update:is-valid', isValid)
            },
        },
    },

    async created() {
        try {
            this.legalDocuments = await REPOSITORY_LEGAL_DOCUMENTS.fetchLegalDocuments()
        } catch (error) {
            alert(error.errorMessage)
        }
    },
})
