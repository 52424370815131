


















import Vue from 'vue'

export default Vue.extend({
    name: 'BaseSection',

    props: {
        centerVertical: Boolean,
        centerHorizontal: Boolean,
    },
})
