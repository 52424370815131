














import Vue from 'vue'
import { IStateSignUp } from '@/types'
import { createNamespacedHelpers } from 'vuex'
import { STORE_MODULE } from '@/enums'
import { FormSignUpCandidate, FormSignUpCompany, Tabs } from './components'

const { mapState } = createNamespacedHelpers(STORE_MODULE.SIGN_UP)

/**
 * @emits 'signed-in'
 * @emits 'signed-up'
 */
export default Vue.extend({
    name: 'FormSignUp',

    components: { FormSignUpCandidate, FormSignUpCompany, Tabs },

    data: () => ({
        isCandidateFormShown: true,
    }),

    computed: {
        ...(mapState(['invitation']) as MapStateToComputed<IStateSignUp>),
    },

    methods: {
        signedUpHandler() {
            this.$emit('signed-up')
        },

        signedInHandler() {
            this.$emit('signed-in')
        },
    },
})
