
































































import Vue from 'vue'
import { BaseCard, BaseCardTitle, BaseCardBody } from '@/components'
import { faUserCircle } from '@fortawesome/free-solid-svg-icons'

export default Vue.extend({
    name: 'Details',

    components: { BaseCard, BaseCardTitle, BaseCardBody },

    props: {
        job: {
            type: Object,
            required: true,
        },
    },

    data: () => ({ faUserCircle }),
})
