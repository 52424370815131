
















import Vue from 'vue'
import { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { BaseField } from '../BaseField'
import { BaseSpinner } from '../../BaseSpinner'
import { ContractType } from './components'
import { IJobContractType } from '@/types'
import { REPOSITORY_JOB_CONTRACT_TYPES } from '@/repositiories/job/jobContractTypes'
import { update } from 'lodash'

interface IJobContractTypeItem {
    contractType: IJobContractType
    checked: boolean
}

export default Vue.extend({
    name: 'BaseFieldContractTypes',

    components: { BaseField, BaseSpinner, ContractType },

    model: {
        prop: 'selectedIds',
        event: 'update:selected-ids',
    },

    props: {
        selectedIds: {
            type: Array as () => number[],
            required: true,
        },
        label: {
            type: String,
            default: 'Contract types',
        },
        icon: Object as () => IconDefinition,
        loading: Boolean,
    },

    data: () => ({
        contractTypes: undefined as undefined | IJobContractType[],
    }),

    computed: {
        contractTypeItems(): IJobContractTypeItem[] | undefined {
            return this.contractTypes?.map((contractType) => ({
                contractType,
                checked: this.selectedIds.includes(contractType.id),
            }))
        },
    },

    async created() {
        try {
            this.contractTypes = await REPOSITORY_JOB_CONTRACT_TYPES.fetchContractTypes()
        } catch (error) {
            alert(error.data.message)
        }
    },

    methods: {
        toggleContractType(typeId: number) {
            const updatedSelectedIds = this.selectedIds.includes(typeId)
                ? this.selectedIds.filter((id) => id !== typeId)
                : [...this.selectedIds, typeId]

            this.$emit('update:selected-ids', updatedSelectedIds)
        },
    },
})
