















import Vue from 'vue'
import generateColor from 'string-to-color'
import { IPerson } from '@/types'
import { getFormattedFullName } from '@/utils'

export default Vue.extend({
    name: 'BaseAvatar',

    props: {
        person: {
            type: Object as () => IPerson,
            required: true,
        },
        size: {
            type: Number,
            default: 30,
        },
        imgUrl: String,
    },

    data: () => ({
        imgLoaded: false,
    }),

    computed: {
        initials(): string {
            const { firstName, lastName } = this.person

            return `${firstName.charAt(0)}${lastName.charAt(0)}`
        },

        backgroundColor(): string {
            const { firstName, lastName } = this.person

            return generateColor([firstName, lastName].join(''))
        },

        style(): object {
            return {
                width: `${this.size}px`,
                height: `${this.size}px`,
                fontSize: `${this.size / 2}px`,
                backgroundColor:
                    this.imgUrl && this.imgLoaded ? 'transparent' : this.backgroundColor,
            }
        },

        title(): string {
            return getFormattedFullName(this.person)
        },

        imgStyle(): object {
            return {
                backgroundImage: `url(${this.imgUrl})`,
            }
        },
    },
})
