import { render, staticRenderFns } from "./InfoSalary.vue?vue&type=template&id=70d8f6cd&scoped=true&"
import script from "./InfoSalary.vue?vue&type=script&lang=ts&"
export * from "./InfoSalary.vue?vue&type=script&lang=ts&"
import style0 from "./InfoSalary.vue?vue&type=style&index=0&id=70d8f6cd&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70d8f6cd",
  null
  
)

export default component.exports