
























import Vue from 'vue'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
import { BaseFieldNumber } from '@/components/BaseField'
import { SkillOptionMenu } from '../SkillOptionMenu'

export default Vue.extend({
    name: 'SkillOptionExperience',

    components: { SkillOptionMenu, BaseFieldNumber },

    model: {
        prop: 'experience',
        event: 'update:experience',
    },

    props: {
        experience: Number,
    },

    data: () => ({ faCalendarAlt }),

    computed: {
        experienceInternal: {
            get(): null | number {
                return this.experience
            },
            set(experience: number | null) {
                this.$emit('update:experience', experience)
            },
        },

        label(): string {
            switch (true) {
                case this.experienceInternal == null:
                    return 'Experience?'
                case this.experienceInternal === 0:
                    return 'No experience'
                case this.experienceInternal === 1:
                    return '1 year'
                default:
                    return `${this.experienceInternal} years`
            }
        },

        isChecked(): boolean {
            return this.experienceInternal != null
        },
    },

    methods: {
        toggle() {
            this.experienceInternal = this.isChecked ? null : 0
        },
    },
})
