












import Vue from 'vue'
import { IUserSignUpCandidateConfig } from '@/types'
import { BaseBtn } from '@/components/BaseBtn'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { REPOSITORY_USER_SIGN_UP } from '@/repositiories'

/**
 * @emits 'signed-up'
 */
export default Vue.extend({
    name: 'BtnSignUp',

    components: { BaseBtn },

    props: {
        payload: {
            type: Object as () => IUserSignUpCandidateConfig,
            required: true,
        },
        isValid: {
            type: Boolean,
            required: true,
        },
    },

    data: () => ({
        isRequestInProgress: false,
        faCheck,
    }),

    methods: {
        async submit() {
            if (!this.isValid) return

            this.isRequestInProgress = true

            try {
                await REPOSITORY_USER_SIGN_UP.createUserCandidate(this.payload)

                this.$emit('signed-up')
            } catch (error) {
                alert(error.data.message)
            }

            this.isRequestInProgress = false
        },
    },
})
