




















import Vue from 'vue'
import { faBook } from '@fortawesome/free-solid-svg-icons'
import { BaseFieldSliderKnowledge } from '@/components/BaseField'
import { SkillOptionMenu } from '../SkillOptionMenu'
import { getFormattedKnowledgeLevel } from '@/utils'

export default Vue.extend({
    name: 'SkillOptionKnowledge',

    components: { SkillOptionMenu, BaseFieldSliderKnowledge },

    model: {
        prop: 'knowledge',
        event: 'update:knowledge',
    },

    props: {
        knowledge: Number,
    },

    data: () => ({ faBook }),

    computed: {
        knowledgeInternal: {
            get(): null | number {
                return this.knowledge
            },
            set(knowledge: number | null) {
                this.$emit('update:knowledge', knowledge)
            },
        },

        label(): string {
            if (this.knowledgeInternal == null) return 'Knowledge?'

            return getFormattedKnowledgeLevel(this.knowledgeInternal)
        },

        isChecked(): boolean {
            return this.knowledgeInternal != null
        },
    },

    methods: {
        toggle() {
            this.knowledgeInternal = this.isChecked ? null : 0
        },
    },
})
