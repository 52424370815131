








import Vue from 'vue'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'

export default Vue.extend({
    name: 'BaseListItemProp',

    props: {
        icon: {
            type: Object as () => IconDefinition,
            required: true,
        },
    },
})
