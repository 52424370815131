var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseCard',[_c('BaseCardTitle',{attrs:{"icon":_vm.faCheckSquare}},[_vm._v("Desirable skills")]),_c('BaseCardBody',[(!_vm.hasDesirableSkills)?_c('div',{staticClass:"text-muted"},[_vm._v("No desirable skills.")]):_c('div',{staticClass:"row"},_vm._l((_vm.job.skillsNice),function(skill){return _c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.title",value:(
                    parseInt(skill.yearsOfExperience) == 1
                        ? '1 year of experience'
                        : skill.yearsOfExperience + ' years of experience'
                ),expression:"\n                    parseInt(skill.yearsOfExperience) == 1\n                        ? '1 year of experience'\n                        : skill.yearsOfExperience + ' years of experience'\n                ",modifiers:{"title":true}}],key:skill.name,staticClass:"col-12 col-sm-6 col-lg-4"},[_c('span',[_vm._v(_vm._s(skill.name))]),_c('br'),(skill.seniority)?_c('BProgress',{attrs:{"max":100,"show-progress":""}},[_c('BProgressBar',{staticClass:"primary",attrs:{"value":parseInt(skill.seniority)}})],1):_vm._e()],1)}),0),_c('div',{staticClass:"row"},_vm._l((_vm.job.groupedSkillsNice),function(group){return _c('div',{key:group.groupName,staticClass:"col-12 col-sm-6 col-lg-12 mt-3"},[_c('div',{staticClass:"group-border p-3"},[_c('h5',{staticClass:"text-primary"},[_vm._v(_vm._s(group.groupName))]),(group.skills.length == group.groupSkillsCount)?_c('div',{staticClass:"span font-italic"},[_vm._v(" All skills from this group are required ")]):_vm._e(),(
                            group.skills.length > group.groupSkillsCount &&
                            group.groupSkillsCount == 1
                        )?_c('div',{staticClass:"span font-italic"},[_vm._v(" At least 1 skill from this group is required ")]):_vm._e(),(
                            group.skills.length > group.groupSkillsCount &&
                            group.groupSkillsCount > 1
                        )?_c('div',{staticClass:"span font-italic"},[_vm._v(" At least "+_vm._s(group.groupSkillsCount)+" skills from this group are required ")]):_vm._e(),_c('div',{staticClass:"row"},_vm._l((group.skills),function(skill){return _c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.title",value:(
                                parseInt(skill.yearsOfExperience) == 1
                                    ? '1 year of experience'
                                    : skill.yearsOfExperience + ' years of experience'
                            ),expression:"\n                                parseInt(skill.yearsOfExperience) == 1\n                                    ? '1 year of experience'\n                                    : skill.yearsOfExperience + ' years of experience'\n                            ",modifiers:{"title":true}}],key:skill.name,staticClass:"col-12 col-sm-6 col-lg-4 mt-3"},[_c('div',[_vm._v(_vm._s(skill.name))]),(skill.seniority)?_c('BProgress',{attrs:{"max":100,"show-progress":""}},[_c('BProgressBar',{staticClass:"primary",attrs:{"value":parseInt(skill.seniority)}})],1):_vm._e()],1)}),0)])])}),0)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }