





















import Vue from 'vue'
import { IconDefinition } from '@fortawesome/free-solid-svg-icons'

export default Vue.extend({
    name: 'BaseBtn',

    props: {
        icon: Object as () => IconDefinition,
        iconRight: Boolean,
        iconClass: String,
        loading: Boolean,
    },

    computed: {
        attrs(): object {
            return {
                type: 'button',
                ...this.$attrs,
            }
        },
    },
})
