









































import Vue from 'vue'
import { BaseCard, BaseCardTitle, BaseCardBody } from '@/components'
// @ts-ignore
import { VueSvgGauge } from 'vue-svg-gauge'
import { faClock } from '@fortawesome/free-solid-svg-icons'

export default Vue.extend({
    name: 'WorkTimeDivision',

    components: { BaseCard, BaseCardTitle, BaseCardBody, VueSvgGauge },

    props: {
        job: {
            type: Object,
            required: true,
        },
    },

    data: () => ({ faClock }),
})
