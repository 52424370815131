























import Vue from 'vue'
import { BaseCard } from '@/components/BaseCard'
import { Location } from 'vue-router'
import { CompanyLogo, JobDetails, JobSalary, JobReferral } from './components'
import { ROUTE } from '@/enums'

export default Vue.extend({
    name: 'BaseJobListItem',

    components: { BaseCard, CompanyLogo, JobDetails, JobSalary, JobReferral },

    props: {
        job: {
            type: Object,
            required: true,
        },
    },

    computed: {
        route(): Location {
            return { name: ROUTE.JOB_DETAILS, params: { jobId: this.job.id } }
        },
    },
})
