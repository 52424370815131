






import Vue from 'vue'

export default Vue.extend({
    name: 'CompanyLogo',

    props: {
        company: {
            type: Object,
            required: true,
        },
    },

    computed: {
        style() {
            return {
                // @ts-ignore
                backgroundImage: `url(${this.company.logo})`,
            }
        },
    },
})
