
















import Vue from 'vue'
import { faMapMarkerAlt, faBriefcase } from '@fortawesome/free-solid-svg-icons'
import { faBuilding } from '@fortawesome/free-regular-svg-icons'
import { BaseListItemProps, BaseListItemProp } from '@/components'

export default Vue.extend({
    name: 'Props',

    components: { BaseListItemProps, BaseListItemProp },

    props: {
        job: {
            type: Object,
            required: true,
        },
    },

    data: () => ({ faBuilding, faMapMarkerAlt, faBriefcase }),

    computed: {
        locationFormatted(): string {
            switch (true) {
                case this.job.remotePercentage === 100:
                    return 'Fully remote'
                case this.job.remotePercentage > 0 && !!this.job.cities:
                    return `${this.job.cities} (partially remote)`
                default:
                    return this.job.cities
            }
        },

        contractTypesFormatted(): string {
            return this.job.contractType.join(', ')
        },
    },
})
