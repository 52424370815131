




import Vue from 'vue'

export default Vue.extend({
    name: 'BaseIntersectionDetector',

    data: () => ({
        observer: undefined as IntersectionObserver | undefined,
    }),

    mounted() {
        this.observer = new IntersectionObserver(this.onIntersect, {
            rootMargin: '50% 0% 50% 0%',
        })
        this.observer.observe(this.$el)
    },

    beforeDestroy() {
        if (this.observer) {
            this.observer.unobserve(this.$el)
        }
    },

    methods: {
        onIntersect(
            entries: IntersectionObserverEntry[],
            observer: IntersectionObserver
        ): void {
            const isIntersected = Boolean(entries.find((entry) => entry.isIntersecting))

            if (isIntersected) {
                this.$emit('is-intersected')
                observer.unobserve(this.$el)
            }
        },
    },
})
