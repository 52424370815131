











import Vue from 'vue'

export default Vue.extend({
    name: 'BaseTab',

    props: {
        selected: Boolean,
        grow: Boolean,
    },
})
