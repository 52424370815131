











import Vue from 'vue'
import { BaseFieldText } from '../BaseFieldText'

export default Vue.extend({
    name: 'BaseFieldPassword',

    components: { BaseFieldText },

    props: {
        value: {
            type: String,
            required: true,
        },
        isValid: Boolean,
    },

    data: () => ({
        showValidationResult: false,
    }),

    computed: {
        password: {
            get(): string {
                return this.value
            },
            set(password: string) {
                this.$emit('input', password)
            },
        },

        isValidInternal(): boolean {
            return this.password.length >= 3 && !/\s/.test(this.password)
        },
    },

    watch: {
        isValidInternal: {
            immediate: true,
            handler(isValid: boolean) {
                this.$emit('update:is-valid', isValid)
            },
        },
    },
})
