<template lang="pug">
  div
    p {{jobTraitType.description}}
      <br>
      span.font-italic.traitAnnotation {{jobTraitType.annotation}}
    p
      .col-12
        Slider(
          v-model='referral.traits[jobTraitType.id]'
          name='referralTrait'
          :min='0'
          :max='100'
          :interval='5'
        )

</template>
<script>
import Slider from 'vue-slider-component'

export default {
    name: 'TraitSlider',

    props: {
        referral: {
            type: Object,
            required: true,
        },

        jobTraitType: {
            type: Object,
            required: true,
        },
    },

    components: {
        Slider,
    },
}
</script>
<style lang="sass" scoped>
.btn-invisible
  visibility: hidden
.traitAnnotation
  font-size: 14px
</style>
