



















import Vue from 'vue'

export default Vue.extend({
    name: 'BaseFieldTypeaheadSuggestion',

    props: {
        disabled: {
            type: Boolean,
            required: true,
        },
        showAlreadySelected: Boolean,
    },
})
