










import Vue from 'vue'
import { IUserSkill, IUuid } from '@/types'
import { BaseList } from '../BaseList'
import { SkillsByCategory } from './components'

interface IUserSkillCategory {
    categoryName: string
    categoryUuid: IUuid
}

interface IUserSkillsByCategory extends IUserSkillCategory {
    userSkills: IUserSkill[]
}

const OWN_CATEGORY_UUID = 'custom'
const OWN_CATEGORY_NAME = 'Custom'

export default Vue.extend({
    name: 'SkillsByCategories',

    components: { BaseList, SkillsByCategory },

    props: {
        userSkills: {
            type: Array as () => IUserSkill[],
            required: true,
        },
        skillComponent: {
            type: Function,
            required: true,
        },
    },

    computed: {
        userSkillsByCategories(): IUserSkillsByCategory[] {
            return this.userSkillCategories.map(({ categoryName, categoryUuid }) => ({
                categoryUuid,
                categoryName,
                userSkills: this.userSkills.filter(
                    (skill) => (skill.categoryUuid || OWN_CATEGORY_UUID) === categoryUuid
                ),
            }))
        },

        userSkillCategories(): IUserSkillCategory[] {
            const categories = this.userSkills.reduce((result, skill) => {
                const categoryUuid = skill.categoryUuid ?? OWN_CATEGORY_UUID
                const categoryName = skill.categoryName ?? OWN_CATEGORY_NAME

                return result.set(categoryUuid, { categoryName, categoryUuid })
            }, new Map<string, IUserSkillCategory>())

            return [...categories.values()]
        },
    },
})
