



















import Vue from 'vue'
import { BaseCard, BaseCardTitle, BaseCardBody } from '@/components'
import { faSmileBeam, faCheckCircle } from '@fortawesome/free-regular-svg-icons'

export default Vue.extend({
    name: 'CompanyPerks',

    components: { BaseCard, BaseCardTitle, BaseCardBody },

    props: {
        job: {
            type: Object,
            required: true,
        },
    },

    data: () => ({ faSmileBeam, faCheckCircle }),
})
