
















import Vue from 'vue'
import { BaseListItemProps, BaseListItemPropChip } from '@/components'

const TAGS_LIMIT = 3

export default Vue.extend({
    name: 'Tags',

    components: { BaseListItemProps, BaseListItemPropChip },

    props: {
        tags: {
            type: Array as () => string[],
            required: true,
        },
    },

    data: () => ({
        isExpanded: false,
    }),

    computed: {
        tagsFiltered(): string[] {
            return this.isExpanded ? this.tags : this.tags.slice(0, TAGS_LIMIT)
        },

        hiddenTags(): number {
            return this.tags.length - this.tagsFiltered.length
        },
    },

    created() {
        // dont constrain if only one more item (more chip will be also 1 more item, so it
        // makes no sense to hide 1 item)
        if (this.tags.length > TAGS_LIMIT + 1) return

        this.isExpanded = true
    },
})
