

























































































import Vue from 'vue'
import { BaseCard, BaseCardTitle, BaseCardBody } from '@/components'
import { faCheckSquare } from '@fortawesome/free-regular-svg-icons'

export default Vue.extend({
    name: 'SkillsDesired',

    components: { BaseCard, BaseCardTitle, BaseCardBody },

    props: {
        job: {
            type: Object,
            required: true,
        },
    },

    computed: {
        hasDesirableSkills(): boolean {
            return !!this.job.skillsNice?.length || !!this.job.groupedSkillsNice?.length
        },
    },

    data: () => ({ faCheckSquare }),
})
