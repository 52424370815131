








import { STORE_MODULE, ACTION_DASHBOARD_COMPANIES } from '@/enums'
import { ICompanyDetails, IStateDashboardCompanies, IStateAuth } from '@/types'
import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'
import { BaseFieldSelect } from '../BaseFieldSelect'
const {
    mapState: mapStateCompanies,
    mapActions: mapActionsCompanies,
} = createNamespacedHelpers(STORE_MODULE.DASHBOARD_COMPANIES)

interface ICompanyDetailsOptions {
    text: string
    value: ICompanyDetails
}

export default Vue.extend({
    name: 'BaseFieldSelectCompany',

    components: { BaseFieldSelect },

    model: {
        prop: 'company',
        event: 'update:company',
    },

    props: {
        company: Object as () => ICompanyDetails,
        isValid: Boolean,
    },
    computed: {
        ...(mapStateCompanies(['companies']) as MapStateToComputed<IStateDashboardCompanies>),
        options(): ICompanyDetailsOptions[] {
            if (!this.companies) return []
            return this.companies.map((c) => ({
                text: c.name,
                value: c,
            }))
        },
        selectedCompany: {
            get(): undefined | ICompanyDetails {
                return this.company
            },
            set(company: ICompanyDetails) {
                this.$emit('update:company', company)
            },
        },
    },

    created() {
        this.fetchAndSetCompanies()
    },

    methods: {
        ...mapActionsCompanies({
            fetchAndSetCompanies: ACTION_DASHBOARD_COMPANIES.FETCH_AND_SET_COMPANIES,
        }),
    },
})
