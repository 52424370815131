<template lang="pug">
  form(action='', v-on:submit.prevent='signUserUp()')
    small.help-block {{error}}
    .form-row
      .col-6
        input.form-control(type='text' placeholder='First name', v-model='firstname')
      .col-6
        input.form-control(type='text' placeholder='Last name', v-model='lastname')
    .form-row.mt-2(v-if="role === 'company_admin'")
      .col-12.mt-2
        input.form-control(type='text' placeholder='Company name' v-model="companyName")
      .col-12.mt-2
        input.form-control(type='text' placeholder='Company website' v-model="companyWebsite")
      .col-12.mt-2
        input.form-control(type='text' placeholder='LinkedIn profile' v-model="linkedinProfile")

    .form-row.mt-2
      .col
        input.form-control(type='email', name='email', :placeholder="role === 'candidate'? 'E-mail' : 'Work e-mail'", v-model='email')
    .form-row.mt-2
      .col
        .input-group
          input.form-control(:type="[!isVisible ? 'password' : 'text']", name='password', placeholder="Password", v-model='password')
          .input-group-append
            .input-group-text
              font-awesome-icon(
                :icon="isVisible ? eyeIcon : eyeSlashIcon" 
                @click="isVisible = !isVisible"
              )

    .form-row
      .col
        small.form-text.text-muted 
          | By signing up you agree to the 
          RouterLink(:to="{ name: ROUTE.PRIVACY_POLICY }" target="_blank")  Privacy Policy
    
    button.btn.btn-lg(
      :class="[responseFromServer != '' ? 'disabled' : '', responseFromServer !== '' ? 'btn-danger' : 'btn-primary']"
      type='submit')
      | {{responseFromServer != '' ? responseFromServer : buttonTitle || 'Sign Up'}}
</template>
<script>
import Multiselect from 'vue-multiselect'
import { ROUTE } from '@/enums'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'

export default {
    name: 'FormSignUpCompany',

    components: {
        Multiselect,
    },

    props: {
        buttonTitle: {
            required: false,
            type: String,
        },
    },

    computed: {
        eyeIcon: () => faEye,
        eyeSlashIcon: () => faEyeSlash,
    },

    data() {
        return {
            ROUTE,
            firstname: '',
            lastname: '',
            linkedinProfile: '',
            email: '',
            password: '',
            companyName: '',
            companyWebsite: '',
            error: '',
            role: 'company_admin',
            isVisible: false,
            responseFromServer: '',
        }
    },

    methods: {
        signUserUp() {
            if (this.validateForm()) {
                this.$store.state.backend
                    .post('/auth/signup', {
                        companyName: this.companyName,
                        companyWebsite: this.companyName,
                        email: this.email,
                        password: this.password,
                        linkedinProfile: this.linkedinProfile,
                        first_name: this.firstname,
                        last_name: this.lastname,
                        role: this.role,
                        status: 'pending',
                    })
                    .then((ret) => {
                        let data = ret.data

                        if (data.state === 'error') {
                            this.error = data.message
                        } else {
                            this.responseFromServer =
                                'Please wait for manual confirmation from our team.'
                        }

                        this.$emit('signed-up')
                    })
                    .catch((error) => alert(error.message))
            }
        },

        validateForm() {
            if (
                this.firstname.trim() == '' ||
                this.lastname.trim() == '' ||
                this.email.trim() == '' ||
                this.password.trim() == '' ||
                this.role.trim() == '' ||
                this.linkedinProfile.trim() == '' ||
                this.companyName.trim() == '' ||
                this.companyWebsite.trim() == ''
            ) {
                this.error = 'Please fill all fields'
                return false
            } else {
                this.error = ''
                return true
            }
        },
    },
}
</script>
<style lang="sass" scoped>
.help-block
  color: red
.text-muted
  margin-bottom: 10px
button
  width: 100%
.shadow
  box-shadow: 0 4px 24px 0 rgba(37, 38, 94, 0.1)
  border: 0
</style>
