








import { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import Vue from 'vue'
import { BaseFieldSelect } from '../BaseFieldSelect'

interface ISelectOption {
    text: string
    value: string
}

const currencies = ['PLN']

export default Vue.extend({
    name: 'BaseFieldCurrency',

    components: { BaseFieldSelect },

    model: {
        prop: 'currency',
        event: 'update:currency',
    },

    props: {
        currency: String,
        label: {
            type: String,
            default: 'Currency',
        },
        icon: Object as () => IconDefinition,
        singleLine: Boolean,
        loading: Boolean,
    },

    computed: {
        options(): ISelectOption[] {
            return currencies.map((isoCode) => ({ text: isoCode, value: isoCode }))
        },

        currencyInternal: {
            get(): string | undefined {
                return this.currency
            },
            set(currency: string) {
                this.$emit('update:currency', currency)
            },
        },
    },

    created() {
        if (!this.currency) this.currencyInternal = currencies[0]
    },
})
