












import Vue from 'vue'
import { Props, Tags } from './components'

export default Vue.extend({
    name: 'JobDetails',

    components: { Props, Tags },

    props: {
        job: {
            type: Object,
            required: true,
        },
    },

    computed: {
        tags(): string[] {
            return this.job.keywords
        },
    },
})
