



















import Vue from 'vue'
import { BaseModal } from '@/components/BaseModal'
import { IUuid, ILegalDocument } from '@/types'

export default Vue.extend({
    name: 'LegalDocumentsItem',

    components: { BaseModal },

    props: {
        document: {
            type: Object as () => ILegalDocument,
            required: true,
        },
        checkedConsentUuids: {
            type: Array as () => IUuid[],
            required: true,
        },
    },

    computed: {
        checkedInternal: {
            get(): IUuid[] {
                return this.checkedConsentUuids
            },
            set(checkedConsentUuids: IUuid[]) {
                this.$emit('update:checked-consent-uuids', checkedConsentUuids)
            },
        },
    },
})
