








import Vue from 'vue'
import { REPOSITORY_JOB_RECRUITMENT_AVAILABILITY } from '@/repositiories'
import { IJobRecruitmentAvailability } from '@/types'
import { BaseFieldSelect } from '../BaseFieldSelect'

interface ISelectOption {
    text: string
    value: IJobRecruitmentAvailability
}

export default Vue.extend({
    name: 'BaseFieldRecruitmentAvailability',

    components: { BaseFieldSelect },

    model: {
        prop: 'recruitmentAvailability',
        event: 'update:recruitment-availability',
    },

    props: {
        recruitmentAvailability: Object as () => IJobRecruitmentAvailability,
    },

    computed: {
        attrs(): object {
            return {
                options: this.options,
                ...this.$attrs,
                loading: !this.recruitmentAvailabilityList || this.$attrs.loading,
            }
        },

        recruitmentAvailabilityInternal: {
            get(): undefined | IJobRecruitmentAvailability {
                return this.recruitmentAvailability
            },
            set(period: IJobRecruitmentAvailability) {
                this.$emit('update:recruitment-availability', period)
            },
        },

        options(): ISelectOption[] {
            return (this.recruitmentAvailabilityList ?? []).map((period) => ({
                text: period.name,
                value: period,
            }))
        },
    },

    data: () => ({
        recruitmentAvailabilityList: undefined as undefined | IJobRecruitmentAvailability[],
    }),

    async created() {
        try {
            this.recruitmentAvailabilityList = await REPOSITORY_JOB_RECRUITMENT_AVAILABILITY.fetchJobRecruitmentAvailabilities()
            this.recruitmentAvailabilityInternal =
                this.recruitmentAvailability || this.recruitmentAvailabilityList[0]
        } catch (error) {
            alert(error.errorMessage)
        }
    },
})
