

















import Vue from 'vue'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

export default Vue.extend({
    name: 'BaseChip',

    data: () => ({ faTimes }),
})
