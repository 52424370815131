













import Vue from 'vue'
import { BaseBtn } from '@/components/BaseBtn'
import { faLifeRing } from '@fortawesome/free-solid-svg-icons'
import { ICredentials } from '@/types'

export default Vue.extend({
    name: 'BtnSubmit',

    components: { BaseBtn },

    props: {
        email: {
            type: String,
            required: true,
        },
        isValid: {
            type: Boolean,
            required: true,
        },
    },

    data: () => ({
        isRequestInProgress: false,
        faLifeRing,
    }),

    methods: {
        async submit() {
            if (!this.isValid) return

            this.isRequestInProgress = true

            try {
                await this.$store.state.backend.post('/auth/forgot', { email: this.email })

                this.$emit('submitted')
            } catch (error) {}

            this.isRequestInProgress = false
        },
    },
})
