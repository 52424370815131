








import Vue from 'vue'
import { faUserCheck } from '@fortawesome/free-solid-svg-icons'
import { BaseBtn } from '../BaseBtn'
import { DialogRefer } from './components'

export default Vue.extend({
    name: 'BaseBtnRefer',

    components: { BaseBtn, DialogRefer },

    props: {
        job: {
            type: Object,
            required: true,
        },
        signUpInvitationUuid: String,
        source: String,
        showInitially: Boolean,
    },

    data() {
        return {
            isDialogShown: this.showInitially,
            faUserCheck,
        }
    },

    computed: {
        attrs(): object {
            return {
                variant: 'primary',
                ...this.$attrs,
                icon: faUserCheck,
            }
        },
    },
})
