




import Vue from 'vue'
import { faTrash, IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { BaseBtn } from '../BaseBtn'

export default Vue.extend({
    name: 'BaseBtnDelete',

    components: { BaseBtn },

    data: () => ({
        isConfirmationShown: false,
    }),

    computed: {
        attrs(): Record<string, unknown> {
            return {
                ...this.$attrs,
                variant: this.variant,
                icon: this.icon,
            }
        },

        listeners(): Record<string, unknown> {
            return {
                ...this.$listeners,
                click: this.clickHandlerWithConfirmation,
            }
        },

        label(): string {
            return this.isConfirmationShown ? 'Are You sure?' : 'Delete'
        },

        variant(): string {
            return this.isConfirmationShown ? 'warning' : 'danger'
        },

        icon(): IconDefinition {
            return this.isConfirmationShown ? faTrash : faTrashAlt
        },
    },

    beforeDestroy() {
        this.resetConfirmation()
    },

    methods: {
        clickHandlerWithConfirmation(event: Event): void {
            event.stopPropagation()

            if (!this.$listeners.click || this.$attrs.loading) return

            if (this.isConfirmationShown) {
                this.callOriginalClickHandlers(event)
            } else {
                this.isConfirmationShown = true
                document.addEventListener('click', this.resetConfirmation)
            }
        },

        callOriginalClickHandlers(event: Event): void {
            if (Array.isArray(this.$listeners.click)) {
                this.$listeners.click.forEach((clickHandler) => clickHandler(event))
            } else {
                this.$listeners.click(event)
            }

            this.isConfirmationShown = false
        },

        resetConfirmation(event?: Event): void {
            document.removeEventListener('click', this.resetConfirmation)

            if (event?.target && this.$el !== event.target) {
                this.isConfirmationShown = false
            }
        },
    },
})
