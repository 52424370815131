



























































































































import Vue from 'vue'
import { TraitSlider } from './components'
import { BaseFieldText, BaseFieldEmail, BaseFieldTextarea } from '@/components/BaseField'
import { mapGetters, mapActions, createNamespacedHelpers } from 'vuex'
import { STORE_MODULE } from '@/enums'
import { getFormattedFullName } from '@/utils'

const { mapState: mapStateAuth } = createNamespacedHelpers(STORE_MODULE.AUTH)

export default Vue.extend({
    name: 'FormReferral',

    components: {
        BaseFieldText,
        BaseFieldEmail,
        BaseFieldTextarea,
        TraitSlider,
    },

    model: {
        prop: 'referral',
        event: 'update:referral',
    },

    props: {
        job: {
            type: Object,
            required: true,
        },
        source: {
            type: String,
            required: false,
            default: () => undefined,
        },
    },

    data() {
        return {
            isValidEmail: false,
            referralStage: 0,
            referral: {
                usersName: '',
                usersEmail: '',
                firstName: '',
                email: '',
                email_body: '',
                inviteToSignUp: false,
                howDoYouKnowThem: '',
                otherReason: '',
                whyAreTheyGood: '',
                traits: {},
            },
        }
    },

    computed: {
        ...mapStateAuth(['userAccount']),
        ...mapGetters('referrals', ['referralTraits']),

        isValidReferral(): boolean {
            return (
                !!this.referral.firstName.length &&
                this.isValidEmail &&
                !!this.referral.email_body.length
            )
        },

        rewardMin(): number {
            return this.job.salaryMin * this.job.referralRewardFactor
        },
        rewardMax(): number {
            return this.job.salaryMax * this.job.referralRewardFactor
        },

        jobReferralTraits(): any {
            
            return this.referralTraits[this.job.id]
        },
    },

    async created() {
       
        await this.fetchTraits(this.job.id)

        this.jobReferralTraits.map((t: any) => {
            
            if (!this.referral.traits[t.id]) this.referral.traits[t.id] = 0
        })
    },

    watch: {
        'referral.firstName'() {
            this.referral.email_body = this.createEmailMessage()
        },

        'isValidEmail'() {
            this.referral.email_body = this.createEmailMessage()
        },
    },

    methods: {
        ...mapActions('referrals', ['fetchTraits']),

        sendReferral() {
            const url = '/referral/new'
            const referral = {
                first_name: this.referral.firstName,
                email: this.referral.email,
                email_body: this.referral.email_body,
                inviteToSignUp: this.referral.inviteToSignUp,
                jobs_id: this.job.id,
                source: this.source, // from career page or our site?
                source_id: this.source ? this.job.company_id : undefined,
                maxReferralBonus: this.rewardMax,
            }

            this.$store.state.backend
                .post(url, referral)
                .then((ret: any) => {
                    if (ret.data.message)
                        return alert(
                            "Referral already exists. You can't refer same user twice."
                        )

                    this.referralStage = 1
                })
                .catch((error: any) => alert('Something went wrong :/\n' + error)) //if already referred let user know
        },

        sendReferralSurvey() {
            const referral = {
                jobs_id: this.job.id,
                email: this.referral.email,
                how_do_you_know_them: this.referral.howDoYouKnowThem,
                other_reason: this.referral.otherReason,
                why_are_they_good: this.referral.whyAreTheyGood,
                traits: {}
            }
            if (
                this.referral.howDoYouKnowThem === 'Worked with them' ||
                this.referral.howDoYouKnowThem === 'Side project with them'
            ) {
                
                referral.traits = this.referral.traits
            }

            this.$store.state.backend
                .post('/referral/survey', referral)
                .then((res: any) => (this.referralStage = 2))
                .catch((err: any) => alert(err))
        },

        createEmailMessage(): string {
            const userFullName = getFormattedFullName(this.userAccount)

            return [
                `Hi, ${this.referral.firstName}!`,
                'Saw this job offer, and thought you would be a great fit :)',
                `Cheers!\n${userFullName}`,
            ].join('\n\n')
        },
    },
})
