var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.userAccount)?_c('div',{staticClass:"current-user"},[_c('RouterLink',{staticClass:"current-user__info",attrs:{"to":{
            name:
                _vm.userAccount.userRole === 'candidate'
                    ? _vm.ROUTE.DASHBOARD_PROFILE
                    : _vm.ROUTE.DASHBOARD,
        }}},[_c('BaseAvatar',{attrs:{"person":_vm.userAccount,"img-url":_vm.userAccount.urlAvatar,"size":36}}),_c('div',{staticClass:"current-user-details"},[_c('div',{staticClass:"current-user-details__name"},[_vm._v(" "+_vm._s(_vm.userAccount.firstName)+" ")]),_c('div',{staticClass:"current-user-details__role"},[_vm._v(" "+_vm._s(_vm.userAccount.userRole)+" ")])])],1),_c('div',{staticClass:"current-user__menu"},[_c('button',{staticClass:"current-user__menu__btn",attrs:{"data-toggle":"dropdown"}},[_c('FontAwesomeIcon',{attrs:{"icon":_vm.faAngleDown}})],1),_c('div',{staticClass:"dropdown-menu dropdown-menu-right"},[_c('div',{staticClass:"d-sm-none dropdown-item disabled"},[_c('p',{staticClass:"mb-0",staticStyle:{"color":"#42bff2"}},[_vm._v(_vm._s(_vm.userAccount.firstName))]),_c('p',{staticClass:"mb-0",staticStyle:{"font-size":"11px","color":"lightgray"}},[_vm._v(" "+_vm._s(_vm.userAccount.userRole)+" ")])]),_c('RouterLink',{staticClass:"dropdown-item",attrs:{"to":{ name: _vm.ROUTE.DASHBOARD_PROFILE }}},[_c('FontAwesomeIcon',{staticClass:"mr-2",attrs:{"icon":_vm.faTachometerAlt}}),_vm._v(" Profile ")],1),(_vm.onboardingState.isCompanySetupCompleted)?_c('RouterLink',{staticClass:"dropdown-item",attrs:{"to":{ name: _vm.ROUTE.DASHBOARD }}},[_c('FontAwesomeIcon',{staticClass:"mr-2",attrs:{"icon":_vm.faBriefcase}}),_vm._v(" Jobs ")],1):_vm._e(),_c('RouterLink',{staticClass:"dropdown-item",attrs:{"to":{
                    name:
                        _vm.userAccount.userRole === 'candidate'
                            ? _vm.ROUTE.DASHBOARD_PROFILE
                            : _vm.ROUTE.DASHBOARD_COMPANY,
                }}},[_c('FontAwesomeIcon',{staticClass:"mr-2",attrs:{"icon":_vm.faCog}}),_vm._v(" Settings ")],1),_c('div',{staticClass:"dropdown-divider"}),_c('a',{staticClass:"dropdown-item",staticStyle:{"cursor":"pointer"},on:{"click":_vm.signOut}},[_c('FontAwesomeIcon',{staticClass:"mr-2",attrs:{"icon":_vm.faSignOutAlt}}),_vm._v(" Log Out ")],1)],1)]),_c('TheTokenAutoRefresher',_vm._b({},'TheTokenAutoRefresher',{ userAccount: _vm.userAccount },false))],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }