








import { IJobContractType } from '@/types'
import Vue from 'vue'

export default Vue.extend({
    name: 'ContractType',

    props: {
        contractType: {
            type: Object as () => IJobContractType,
            required: true,
        },
        checked: {
            type: Boolean,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: true,
        },
    },

    methods: {
        toggleContractType() {
            if (this.disabled) return

            this.$emit('toggle', this.contractType.id)
        },
    },
})
