























import Vue from 'vue'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { BaseFieldEmail } from '@/components/BaseField'
import { BtnSubmit } from './components'

export default Vue.extend({
    name: 'FormResetPassword',

    components: { BaseFieldEmail, BtnSubmit },

    model: {
        prop: 'isShown',
        event: 'update:is-shown',
    },

    props: {
        isShown: {
            type: Boolean,
            required: true,
        },
        email: {
            type: String,
            required: true,
        },
    },

    data: () => ({
        isValid: false,
        faTimes,
    }),

    computed: {
        emailInternal: {
            get(): string {
                return this.email
            },
            set(email: string) {
                this.$emit('update:email', email)
            },
        },
    },

    methods: {
        submissionHandler() {
            this.$emit('submitted')
            this.close()
        },

        close() {
            this.$emit('update:is-shown', false)
        },
    },
})
