














import Vue from 'vue'
import { IStateSignUp } from '@/types'
import { AUTHENTICATION_TAB_ID } from './enums'
import { ITabAuthentication } from './types'
import { BaseSpinner } from '@/components/BaseSpinner'
import { Tabs, FormSignIn, FormSignUp } from './components'
import { createNamespacedHelpers } from 'vuex'
import { ACTION_SIGN_UP, MUTATION_SIGN_UP, STORE_MODULE } from '@/enums'

const { mapState, mapMutations, mapActions } = createNamespacedHelpers(STORE_MODULE.SIGN_UP)

/**
 * @emits 'signed-in'
 */
export default Vue.extend({
    name: 'BaseFormAuthenticate',

    components: { BaseSpinner, Tabs },

    props: {
        signUpInvitationUuid: String,
        showSignUpForm: Boolean,
    },

    data: () => ({
        selectedTab: undefined as undefined | ITabAuthentication,
    }),

    computed: {
        ...(mapState(['invitationUuid', 'invitation']) as MapStateToComputed<IStateSignUp>),

        tabs(): ITabAuthentication[] {
            return [
                {
                    id: AUTHENTICATION_TAB_ID.SIGN_IN,
                    name: 'Sign in',
                    formComponent: FormSignIn,
                },
                {
                    id: AUTHENTICATION_TAB_ID.SIGN_UP,
                    name: 'Sign up',
                    formComponent: FormSignUp,
                },
            ]
        },

        invitationUuidInternal(): string | undefined {
            return this.signUpInvitationUuid || this.invitationUuid
        },

        isLoading(): boolean {
            return !this.selectedTab
        },
    },

    watch: {
        tabs(tabs: ITabAuthentication[]) {
            if (tabs.includes(this.selectedTab!)) return

            this.selectedTab = this.tabs[0]
        },
    },

    async created() {
        if (!this.invitationUuidInternal) {
            this.selectedTab = this.tabs[this.showSignUpForm ? 1 : 0]
        } else {
            try {
                await this.fetchAndSetInvitation(this.invitationUuidInternal)

                this.selectedTab = this.tabs.find(
                    ({ id }) => id === AUTHENTICATION_TAB_ID.SIGN_UP
                )
            } catch (error) {
                this.selectedTab = this.tabs[0]
            }
        }
    },

    methods: {
        ...mapMutations({
            setInvitationUuid: MUTATION_SIGN_UP.SET_INVITATION_UUID,
            setInvitation: MUTATION_SIGN_UP.SET_INVITATION,
        }),

        ...mapActions({ fetchAndSetInvitation: ACTION_SIGN_UP.FETCH_AND_SET_INVITATION }),

        signInHandler() {
            this.clearInvitationData()
            this.$emit('signed-in')
        },

        signUpHandler() {
            this.clearInvitationData()
        },

        clearInvitationData() {
            if (this.invitationUuid) this.setInvitationUuid(undefined)
            if (this.invitation) this.setInvitation(undefined)
        },
    },
})
