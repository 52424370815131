















import Vue from 'vue'
import { faList, IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { BaseSpinner } from '../../BaseSpinner'

export default Vue.extend({
    name: 'BaseList',

    components: { BaseSpinner },

    props: {
        items: Array,
        placeholderIcon: {
            type: Object as () => IconDefinition,
            default: () => faList,
        },
        placeholderText: {
            type: String,
            default: 'No results',
        },
    },
})
