



























import Vue from 'vue'
import { faTag, faTimes } from '@fortawesome/free-solid-svg-icons'
import { SkillOptionKnowledge, SkillOptionExperience } from './components'

export default Vue.extend({
    name: 'BaseUserSkillEditable',

    components: { SkillOptionKnowledge, SkillOptionExperience },

    data: () => ({ faTag, faTimes }),

    props: {
        experience: Number,
        knowledge: Number,
    },

    computed: {
        experienceInternal: {
            get(): null | number {
                return this.experience
            },
            set(experience: number | null) {
                this.$emit('update:experience', experience)
            },
        },

        knowledgeInternal: {
            get(): null | number {
                return this.knowledge
            },
            set(knowledge: number | null) {
                this.$emit('update:knowledge', knowledge)
            },
        },
    },
})
