




import Vue from 'vue'
import { REPOSITORY_JOB_NOTICE_PERIODS } from '@/repositiories'
import { IJobNoticePeriod } from '@/types'
import { BaseFieldSelect } from '../BaseFieldSelect'

interface ISelectOption {
    text: string
    value: IJobNoticePeriod
}

export default Vue.extend({
    name: 'BaseFieldNoticePeriod',

    components: { BaseFieldSelect },

    model: {
        prop: 'noticePeriod',
        event: 'update:notice-period',
    },

    props: {
        noticePeriod: Object as () => IJobNoticePeriod,
    },

    computed: {
        attrs(): object {
            return {
                options: this.options,
                ...this.$attrs,
                loading: !this.noticePeriodList || this.$attrs.loading,
            }
        },

        noticePeriodInternal: {
            get(): undefined | IJobNoticePeriod {
                return this.noticePeriod
            },
            set(period: IJobNoticePeriod) {
                this.$emit('update:notice-period', period)
            },
        },

        options(): ISelectOption[] {
            return (this.noticePeriodList ?? []).map((period) => ({
                text: period.name,
                value: period,
            }))
        },
    },

    data: () => ({
        noticePeriodList: undefined as undefined | IJobNoticePeriod[],
    }),

    async created() {
        try {
            this.noticePeriodList = await REPOSITORY_JOB_NOTICE_PERIODS.fetchNoticePeriods()
            this.noticePeriodInternal = this.noticePeriod || this.noticePeriodList[0]
        } catch (error) {
            alert(error.errorMessage)
        }
    },
})
