























import Vue from 'vue'
import { IFile } from '@/types'
import { BaseSpinner } from '../../BaseSpinner'

const MAX_FILE_SIZE_MB = 5
const MAX_FILE_SIZE = MAX_FILE_SIZE_MB * 1e6

export default Vue.extend({
    name: 'BaseFieldFile',

    components: { BaseSpinner },

    model: {
        prop: 'files',
        event: 'update:files',
    },

    props: {
        files: {
            type: Array as () => IFile[],
            required: true,
        },
        label: {
            type: String,
            default: 'Attach file',
        },
        singleLine: Boolean,
        loading: Boolean,
    },

    computed: {
        filesInternal: {
            get(): undefined {
                /* IFile cannot be transformed back to file */
                return undefined
            },
            set(files: File | File[]) {
                this.updateFiles(Array.isArray(files) ? files : [files])
            },
        },

        attrs(): {} {
            return {
                ...(this.singleLine && { placeholder: this.label }),
                ...this.$attrs,
                disabled: this.isReadingFiles || this.$attrs.disabled,
            }
        },

        maxFileSizeFormatted(): string {
            return `${MAX_FILE_SIZE_MB}MB`
        },
    },

    data: () => ({
        isReadingFiles: false,
    }),

    methods: {
        async updateFiles(files: File[]) {
            this.isReadingFiles = true

            const parsedFiles = await this.getParsedFiles(files)
            this.$emit('update:files', parsedFiles)

            this.isReadingFiles = false
        },

        async getParsedFiles(files: File[]): Promise<IFile[]> {
            const filesToParse = files.filter((file) => file.size <= MAX_FILE_SIZE)

            return Promise.all(filesToParse.map(this.getParsedFile))
        },

        async getParsedFile(file: File): Promise<IFile> {
            const { lastModified, name, size, type } = file
            const content = await this.getFileAsArrayBuffer(file)

            return {
                lastModified,
                name,
                size,
                type,
                content,
            }
        },

        async getFileAsArrayBuffer(file: File): Promise<ArrayBuffer> {
            const fileReader = new FileReader()
            const arrayBuffer: Promise<ArrayBuffer> = new Promise((resolve, reject) => {
                fileReader.onload = (event) => {
                    if (!event.target?.result || typeof event.target.result === 'string') {
                        return reject(event)
                    }

                    resolve(event.target.result)
                }
            })

            fileReader.readAsArrayBuffer(file)

            return arrayBuffer
        },
    },
})
